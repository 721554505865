import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ApplicationRef } from '@angular/core';

import { AppComponent } from './app.component';
import {HeaderModule} from 'ng-admin-lte';
import { AppRoutingModule } from './/app-routing.module';
import { DevicesMapComponent } from './devices-map/devices-map.component';
import { DeviceOverviewComponent } from './device-overview/device-overview.component'

import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { firebaseConfig } from './firebase/config';

import { AgmCoreModule } from '@agm/core';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FlatCounterComponent } from './widget/flat-counter/flat-counter.component';
import { RealTimeChartComponent } from './widget/real-time-chart/real-time-chart.component';
import { CalendarHeatMapComponent } from './widget/calendar-heat-map/calendar-heat-map.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HourHeatMapComponent } from './widget/hour-heat-map/hour-heat-map.component';
import { RealTimeChartPageComponent } from './embed/real-time-chart-page/real-time-chart-page.component';
import { HourlyHeatmapPageComponent } from './embed/hourly-heatmap-page/hourly-heatmap-page.component';


@NgModule({
  declarations: [
    AppComponent,
    DevicesMapComponent,
    DeviceOverviewComponent,
    FlatCounterComponent,
    RealTimeChartComponent,
    CalendarHeatMapComponent,
    HourHeatMapComponent,
    RealTimeChartPageComponent,
    HourlyHeatmapPageComponent
  ],
  entryComponents: [AppComponent, RealTimeChartPageComponent, HourlyHeatmapPageComponent
  ],
  imports: [
    BrowserModule,HeaderModule, AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCYoKG6_lF5DoBPtHv9vPdtqi__QoPy1ic'
    }),
    ChartsModule,NgxChartsModule,BrowserAnimationsModule
  ],
  providers: []
  // bootstrap: [AppComponent]
})
export class AppModule { 
  ngDoBootstrap(appRef: ApplicationRef) {
    console.log("boot")
    var statusElement = document.querySelector('#embed');
    if (document.location.href.includes("embed")) {
      document.body.setAttribute("style","background-color: transparent;")
      if (document.location.href.includes("hourly")) {
      const component = HourlyHeatmapPageComponent
      appRef.bootstrap(component);
      }else {
        const component = RealTimeChartPageComponent;
        appRef.bootstrap(component);
      }
      
    } else {
      const component = AppComponent;
      appRef.bootstrap(component);
    }
    
  }
}
