import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

const monthName = new Intl.DateTimeFormat('nl-be', { month: 'short' });
const weekdayName = new Intl.DateTimeFormat('nl-be', { weekday: 'short' });

@Component({
  selector: 'ax-calendar-heat-map',
  templateUrl: './calendar-heat-map.component.html',
  styleUrls: ['./calendar-heat-map.component.css']
})
export class CalendarHeatMapComponent implements OnInit, OnChanges {
  @Input() detector: string;
  @Input() name: string;
  @Input() title: string;

  view: any[];//= ['100%', '400px'];
  calendarData: any[];
  showLegend = true;
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  schemeType = 'ordinal';
  innerPadding = '10%';
  tooltipDisabled = false;
  stats: any;

  colorScheme = {
//    domain: ['#E0E0E0',  '#AAAAAA',  '#AAAAAA',  '#AAAAAA']
    domain: ['#fff8e1', '#ffecb3', '#ffe082', '#ffd54f', '#ffca28', '#ffc107', '#ffb300', '#ffa000', '#ff8f00', '#ff6f00']

  };

  legendOptions: any;

  constructor(private db: AngularFireDatabase) {

  }
  ngOnChanges() {
    console.log("in changes")
    const now = new Date();
    const todaysDay = now.getDate();
    const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

    // Monday
    const thisMonday = new Date(thisDay.getFullYear(), thisDay.getMonth(), todaysDay - thisDay.getDay() + 1);
    const thisMondayDay = thisMonday.getDate();
    const thisMondayYear = thisMonday.getFullYear();
    const thisMondayMonth = thisMonday.getMonth();
    const getDate = d => new Date(thisMondayYear, thisMondayMonth, d);

    this.stats = this.db.object("/stats/" + this.detector + '/day').valueChanges();
    this.stats.subscribe((snapshot: any) => {
      if (!snapshot) snapshot = {};
      this.calendarData = []
      const getDate = d => new Date(thisMondayYear, thisMondayMonth, d);
      for (let week = -10; week <= 0; week++) {
        const mondayDay = thisMondayDay + (week * 7);
        const monday = getDate(mondayDay);

        // one week
        const series = [];
        for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
          const date = getDate(mondayDay - 1 + dayOfWeek);

          // skip future dates
          if (date > now) {
            continue;
          }
          

          // value
          //const value = (dayOfWeek < 6) ? (date.getMonth() + 1) : 0;
          var value = 0;
          
          if (snapshot[Date['dateAsString2'](date)]) {
            value = snapshot[Date['dateAsString2'](date)];
          }

          series.push({
            date,
            name: weekdayName.format(date),
            value
          });
        }


        this.calendarData.push({
          name: monday.toString(),
          series
        });
      }
    });
  }
  ngOnInit() {
    // this.view = [700, 500];
    this.view = undefined;
    // this.calendarData = this.getCalendarData();
    this.schemeType === 'ordinal'
    this.legendOptions = this.getLegendOptions();
  }


  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: 'waarde'
    };

    return opts;
  }
//   getCalendarData(): any[] {
//     // today
//     const now = new Date();
//     const todaysDay = now.getDate();
//     const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

//     // Monday
//     const thisMonday = new Date(thisDay.getFullYear(), thisDay.getMonth(), todaysDay - thisDay.getDay() + 1);
//     const thisMondayDay = thisMonday.getDate();
//     const thisMondayYear = thisMonday.getFullYear();
//     const thisMondayMonth = thisMonday.getMonth();

//     // 52 weeks before monday
//     const calendarData = [];
//     const getDate = d => new Date(thisMondayYear, thisMondayMonth, d);
//     for (let week = -52; week <= 0; week++) {
//       const mondayDay = thisMondayDay + (week * 7);
//       const monday = getDate(mondayDay);

//       // one week
//       const series = [];
//       for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
//         const date = getDate(mondayDay - 1 + dayOfWeek);

//         // skip future dates
//         if (date > now) {
//           continue;
//         }

//         // value
//         //const value = (dayOfWeek < 6) ? (date.getMonth() + 1) : 0;
//         const value = 0;

//         series.push({
//           date,
//           name: weekdayName.format(date),
//           value
//         });
//       }
//       console.log(series)

// console.log(series)
//       calendarData.push({
//         name: monday.toString(),
//         series
//       });
//     }
//     return calendarData;
//   }

  calendarAxisTickFormatting(mondayString: string) {
    const monday = new Date(mondayString);
    const month = monday.getMonth();
    const day = monday.getDate();
    const year = monday.getFullYear();
    const lastSunday = new Date(year, month, day - 1);
    const nextSunday = new Date(year, month, day + 6);
    return (lastSunday.getMonth() !== nextSunday.getMonth()) ? monthName.format(nextSunday) : '';
  }
  calendarTooltipText(c): string {
    return `
      <span class="tooltip-label">${c.label} • ${c.cell.date.toLocaleDateString()}</span>
      <span class="tooltip-val">${c.data.toLocaleString()}</span>
    `;
  }
}
