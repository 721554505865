import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
  selector: 'ax-real-time-chart',
  templateUrl: './real-time-chart.component.html',
  styleUrls: ['./real-time-chart.component.css']
})
export class RealTimeChartComponent implements OnInit, OnChanges {
  measurements: any;
  samplesArray: any[] =[];
  sampleSize = 100;
  timePerBar = 20000;
  @Input('title') title = "Live teller"
  @Input() detector: string;
  @Input() name: string;

  constructor(private db: AngularFireDatabase) {
    setInterval(this.intervalFct,this.timePerBar/2);

  }
  ngOnInit() {
  }

  ngOnChanges(){
  
    this.measurements = this.db.list("/datemeasurements/" + this.detector+'/'+Date['dateAsString'](new Date())).valueChanges();

    this.measurements.subscribe((snapshot:any)=>{
        this.samplesArray = [];
        snapshot.forEach((element:any) => {

            this.measurementToSamplesArray(element);
        })
        this.samplesToGraph();

    });
    this.barChartData[0].label = this.name;
}

measurementToSamplesArray(measurement:any){
  this.samplesArray.push(measurement);
}

samplesToGraph(){
  for (var i =0 ; i < this.sampleSize; i ++){
      this.barChartData[0].data[i] = 0;
      if ((i%10)==0) this.barChartLabels[i] = '-'+Math.round(i/3)+"min";
  }
  var date = Date.now();
  var rest = date % this.timePerBar;
  var date = date - rest + this.timePerBar;
  for (var i =0 ; i < this.samplesArray.length; i ++){
      
      var index = (date - this.samplesArray[i].date )/this.timePerBar;

      this.barChartData[0].data[Math.floor(index)] += Math.floor(Number.parseInt(this.samplesArray[i].value)/2);
  }
  this.barChartData = [this.barChartData[0]]
}

intervalFct: any =()=>{
  this.samplesToGraph();
}

public lineChartOptions:any = {
  responsive: true,
  responsiveAnimationDuration: 0,
  animation: false,
  scales:{
      xAxes:[
          {
              ticks:{     
                suggestedMax: 10,
                min: 0,
                beginAtZero:true,
                  maxTicksLimit:6
              }
          }
      ],
      
      yAxes: [
          {
          ticks: {
              suggestedMax: 5,
              min: 0,
              beginAtZero:true,
              
          }
      }
      ]
  }
};
public barChartData:any[] = [
  {data: [], label: 'Series A'}
];
public lineChartLabels: Array<any> = [];
public barChartLabels:string[] = [];
public barChartType:string = 'bar';
public barChartLegend:boolean = true;


}
