import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-devices-map',
  templateUrl: './devices-map.component.html',
  styleUrls: ['./devices-map.component.css'],
  styles: [`
  agm-map {
    height: 500px;
  }
`],
})
export class DevicesMapComponent implements OnInit {
  devices: any[] = [];
  lat: number = 51;
  lng: number = 5.9;

  markerIconUrl(c: any) {

    // return require('../assets/images/bicycle2.png')    
  }

  public id: string;
  device: any = [];// FirebaseObjectObservable<any>;
  items: Observable<any[]>;
  constructor(private route: ActivatedRoute, private router: Router, private db: AngularFireDatabase) {
 
  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');


    this.items = this.db.list('devices').valueChanges();
    this.items.subscribe((snapshot: any) => {

      this.devices = [];
      snapshot.map(device => {
        
        
        var entry = {
          lat: device.lat,
          lng: device.lng,
          id: device.id,
          key: device.id,

          labelOptions: {
            color: '#000000',
            fontFamily: '',
            fontSize: '14px',
            fontWeight: 'bold',

          }
        }
        this.devices.push(entry);

      });
      //     this.device = snapshot;
    });
  }

  subscribers = {};
  markerClick(device) {
    if (!this.subscribers[device.id]){
      this.subscribers[device.id] = true
      this.db.object("/stats/" + device.id + '/day/'+Date['dateAsString2'](new Date())).valueChanges().subscribe((snapshot: any) => {
        device.bikestoday = Number.parseInt(snapshot);
        if (!device.bikestoday) device.bikestoday = 0
      });
    }
    
  }
}
