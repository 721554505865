import { Component } from '@angular/core';


Number['format2digits'] = function (x) { return ("0" + x).slice(-2); };

Date['dateAsString'] = function (date) {
    return date.getFullYear() + "-" + Number['format2digits'](date.getMonth() + 1) + "-" +  Number['format2digits'](date.getUTCDate());
}

Date['dateAsString2'] = function (date) {
  return date.getFullYear() + "-" + Number['format2digits'](date.getMonth() + 1) + "-" +  Number['format2digits'](date.getDate());
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
}
