import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.css']
})
export class DeviceOverviewComponent implements OnInit {
  public id:string;

  constructor(private route: ActivatedRoute, private router: Router) { 
       
  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
        
    //this.device = this.db.object("/devices/"+this.id);
  }

}
