import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import {AppComponent} from './app.component'
import {DevicesMapComponent} from './devices-map/devices-map.component'
import {DeviceOverviewComponent} from './device-overview/device-overview.component'

const routes: Routes = [
    { path: '',  component: DevicesMapComponent},
    { path: 'map',  component: DevicesMapComponent},
    { path: 'counter/:id', component: DeviceOverviewComponent },

    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
