import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

const monthName = new Intl.DateTimeFormat('nl-be', { month: 'short' });
// const weekdayName = new Intl.DateTimeFormat('nl-be', { weekday: 'short' });

@Component({
  selector: 'ax-hour-heat-map',
  templateUrl: './hour-heat-map.component.html',
  styleUrls: ['./hour-heat-map.component.css']
})
export class HourHeatMapComponent implements OnInit, OnChanges {
  @Input() detector: string;
  @Input() name: string;
  @Input() title: string;

  view: any[];//= ['100%', '400px'];
  calendarData: any[];
  showLegend = true;
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  schemeType = 'ordinal';
  innerPadding = '10%';
  tooltipDisabled = false;
  stats: any;
  hourdaydata = [ 
  ];
  colorScheme = {
    domain: ['#fff8e1', '#ffecb3', '#ffe082', '#ffd54f', '#ffca28', '#ffc107', '#ffb300', '#ffa000', '#ff8f00', '#ff6f00']
  };

  legendOptions: any;

  constructor(private db: AngularFireDatabase) {

  }
  ngOnChanges() {
   
    const now = new Date();
    const todaysDay = now.getDate();
    const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);
    this.stats = this.db.object("/stats/" + this.detector + '/hour').valueChanges();
    
    this.stats.subscribe((snapshot: any) => {
      // console.log(snapshot);
      var hourd = []
      for (let day = 20; day >= 0; day--) {
          var date = new Date(now.getTime() - day*24*3600000)
          if (snapshot[Date['dateAsString2'](date)]) {
            var hs = Object.keys(snapshot[Date['dateAsString2'](date)]);
            var serie = {
              "name": ""+Date['dateAsString2'](date),    
              "series": []
            }
            for (let h=23; h>=0; h--) {
              serie.series.push({
                name: h+" u",
                value: snapshot[Date['dateAsString2'](date)][h+""]
              })
            }
            hourd.push(serie);
          } else {
            var serie = {
              "name": ""+Date['dateAsString2'](date),    
              "series": []
            }
            for (let h=23; h>=0; h--) {
              serie.series.push({
                name: h+" u",
                value: 0
              })
            }
            hourd.push(serie);
          }
      }
      
      this.hourdaydata =hourd;
      
     
    });
   
  }
  ngOnInit() {
    // this.view = [700, 500];
    this.view = undefined;
    this.schemeType === 'ordinal'
    this.legendOptions = this.getLegendOptions();
  }


  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: 'waarde'
    };

    return opts;
  }


  calendarTooltipText(c): string {
    return `
      <span class="tooltip-label">${c.label} • ${c.cell.date.toLocaleDateString()}</span>
      <span class="tooltip-val">${c.data.toLocaleString()}</span>
    `;
  }
}
