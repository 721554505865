import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './real-time-chart-page.component.html',
  styleUrls: ['./real-time-chart-page.component.css']
})
export class RealTimeChartPageComponent implements OnInit {
  id:string= "";
  title:string = "Live counter"
  constructor( private location: Location ){
    this.id = this.getParameterByName('id');
    var dyTitle = this.getParameterByName('title');
    if (dyTitle) {
      this.title = dyTitle;
    }
  }

  ngOnInit() {
    setTimeout(()=>{
      

      this.location.replaceState("/embed/?id="+this.id+"&title="+this.title);
    },1000)
    
  }


  getParameterByName(name: string) {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
}
