import { Component , Input, OnChanges} from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
  selector: 'ax-flat-counter',
  templateUrl: './flat-counter.component.html',
  styleUrls: ['./flat-counter.component.css']
})
export class FlatCounterComponent implements OnChanges {
  measurements: any;

  @Input() detector: string;
  @Input() name: string;

  constructor(private db: AngularFireDatabase) {

  }

  ngOnChanges(){
    this.measurements = this.db.list("/datemeasurements/" + this.detector+'/'+Date['dateAsString'](new Date())).valueChanges();
    this.measurements.subscribe((snapshot:any)=>{
        this.samplesArray = [];
        snapshot.forEach((element:any) => {

            this.measurementToSamplesArray(element);
        })
        

    });
    
}

 getCount() {
        var count = 0;
        for (var i=0;i< this.samplesArray.length;i++) {
            count += Number.parseInt(this.samplesArray[i].value);
        }
        return Math.floor((count+0.5)/2);
    }
    samplesArray: any[] =[];

    measurementToSamplesArray(measurement:any){
        if (measurement.date > Date.now()-7200000){
            this.samplesArray.push(measurement);
        }
            
    }

}
